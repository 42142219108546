const vtulEnums = require('../../../cross/index').enum;

const { executionStatus } = vtulEnums.execution;
export default class ExecutionStatus {
  static getStatus(execution) {
    if (execution.finishedOn) return executionStatus.FINISHED.value;
    if (!execution.startedOn) return executionStatus.SCHEDULED.value;
    return executionStatus.RUNNING.value;
  }
}
