<template>
  <span v-if="parameters"> with
    <b-dropdown
      position="is-top-left"
      aria-role="list"
    >
      <strong
        slot="trigger"
        style="border-bottom: 1px dashed #b5b5b5; cursor: pointer;"
      >{{ parameters.length }} {{ parameters.length === 1 ? 'parameter' : 'parameters' }}.</strong>
      <div :style="`max-width: ${maxWidth}; width: max-content; margin: auto;`">
        <div style="padding: 0px 10px;color: #4a4a4a; text-align: left;">
          <p
            v-for="parameter in parameters"
            :key="parameter.key"
          >
            <strong>{{ parameter.name }}: </strong>
            <span> {{ parameter.value }}</span>
          </p>
        </div>
      </div>
    </b-dropdown>
  </span>
</template>

<script>

import { cleanExecutionParams } from '../../../../cross/src/helpers/cleanExecutionParams';

export default {
  name: 'ExecutionResultParameters',
  props: {
    executionParameters: {
      type: Object,
      default() {
        return { };
      },
    },
  },
  data() {
    return {
      parameters: [],
      maxWidth: '400px',
    };
  },
  watch: {
    executionParameters() {
      this.parseParameters();
    },
  },
  created() {
    this.parseParameters();
  },
  methods: {
    parseParameters() {
      if (!this.executionParameters) {
        this.parameters = undefined;
        return;
      }

      this.parameters = cleanExecutionParams(this.executionParameters);
    },
  },
};
</script>
