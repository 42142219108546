function customizeParameters(param) {
  const { key, index } = param;
  let { value } = param;
  let name = '';
  switch (key) {
    case 'onlyOnlineDevices':
      name = 'Run only in online devices';
      break;
    default:
      name = `${key.charAt(0).toUpperCase()}${key.slice(1).split(/(?=[A-Z])/).join(' ')}`;
      break;
  }
  if (typeof value === 'boolean') {
    value = value.toString();
    value = value.charAt(0).toUpperCase() + value.slice(1); // capitalize first letter
  }
  return {
    key: index,
    name,
    value,
  };
}

const cleanExecutionParams = (parameters) => {
  const parseParameters = [];
  if (!parameters) {
    return parseParameters;
  }
  const invalidParams = ['signalClientID', 'url', 'execute', 'user', 'password'];
  Object.keys(parameters).forEach((key, index) => {
    if (!invalidParams.includes(key)
      && parameters[key] !== null && parameters[key] !== undefined) {
      parseParameters.push(customizeParameters({ key, value: parameters[key], index }));
    }
  });
  return parseParameters;
};

module.exports = {
  cleanExecutionParams,
};
