<template>
  <div>
    <a
      v-if="(executionRow.action === executeAction.GET_AGENT_LOGS.value.id
        || executionRow.action === executeAction.GET_OLD_BOARDGENT_AGENT_LOGS.value.id)
        && executionRow.exitCode === '0'"
      :href="executionRow.executionResult"
    >AgentLogs.zip</a>
    <p
      v-else-if="executionRow.exitCode == operationalApiErrors.CANCELED_EXECUTION.value.code"
    >
      <span v-if="showText">
        {{ executionRow.executionResult }} on {{ executionRow.finishedOn | moment }}.
      </span>
      <span v-else>{{ executionRow.executionResult }}</span>
    </p>
    <p
      v-else-if="executionRow.exitCode == operationalApiErrors.OFFLINE_DEVICE.value.code"
    >
      <span v-if="showText">{{ executionRow.executionResult }}</span>
      <span v-else>{{ operationalApiErrors.OFFLINE_DEVICE.value.messageShort }}</span>
    </p>
    <p
      v-else-if="showText"
      :style="textStyle"
      v-html="text"
    />
    <p
      v-else
      id="previewText"
      v-html="text"
    />
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

const { operationalApiErrors } = vtulEnums.enum.api;
const { executeAction } = vtulEnums.enum.execution;

export default {
  name: 'ExecutionResultDetails',
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    executionRow: {
      type: Object,
      default() {
        return {};
      },
    },
    viewResultExecution: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      executeAction,
      operationalApiErrors,
    };
  },
  computed: {
    text() {
      const resultText = this.executionRow.executionResult ? this.executionRow.executionResult.trim() : '';
      if (!resultText) {
        return 'Scheduled and waiting for the device to start. You can cancel the execution if you need it.';
      }
      const escapedHtml = resultText.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return escapedHtml;
    },
    textStyle() {
      return {
        whiteSpace: 'pre-wrap',
        wordBreak: 'keep-all',
        fontFamily: this.executionRow.action === this.executeAction.COMMAND.value.id
        || this.executionRow.action === this.executeAction.POWERSHELL.value.id
        || this.executionRow.action === this.executeAction.WMI.value.id
          ? 'monospace, monospace' : '',
      };
    },
  },
};
</script>
<style scoped>
#previewText {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1;
   -webkit-box-orient: vertical;
}
</style>
