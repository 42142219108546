<template>
  <card
    v-if="haveRelatedExecutions
      && executionActionsEnum.find(x => x.value.id === executionGroup.action) != undefined"
    ref="executionCard"
    internal-card="card material-card execution-group-card"
  >
    <card
      slot="header"
      style="flex-grow: 1; box-shadow: 0 1px 2px hsla(0,0%,4%,.1)"
      url-help=""
    >
      <div
        slot="header"
        class="columns is-tablet"
        style="flex-grow: 1; margin: 0px !important; padding: 0px !important;"
      >
        <div
          class="column"
          style="font-weight: bold"
        >
          <a
            v-if="!displayingExecutionDetails"
            class="is-text "
            style="padding:0px;color:#4a4a4a;text-decoration: underline;width:20%; "
            @click="goToExecution(executionGroup)"
          >
            {{ executionActionsEnum.find(x => x.value.id === executionGroup.action).value.name }}
          </a>
          <span v-else>
            {{ executionActionsEnum.find(x => x.value.id === executionGroup.action).value.name }}
          </span>
        </div>
        <div class="is-flex is-justify-content-flex-end is-align-items-center pr-2">
          <div
            v-if="!filtersState || (!filtersState.DEVICE.value.selectedValue
              && filtersState.STATE.value.selectedValue !== executionStatus.SCHEDULED.value
              && filtersState.STATE.value.selectedValue !== executionStatus.RUNNING.value)"
            class="hideOfflineDevicesToggle ml-4"
            style="margin-right:20px"
          >
            <b-switch
              v-model="showCanceled"
              type="is-dark"
              size="is-small"
            >
              Show {{ canceledExecutionsTotal }} canceled
            </b-switch>
          </div>
          <div>
            <b-button
              v-if="checkedCancelableExecutions < 1"
              disabled
              class="button is-small is-primary is-light"
            >
              Cancel scheduled executions
            </b-button>
            <b-button
              v-else
              class="button is-small is-primary is-light"
              @click="sendExecutionsToCancel()"
            >
              {{ `Cancel ${checkedCancelableExecutions.length}
              Scheduled execution${checkedCancelableExecutions.length > 1 ? 's' : ''}` }}
            </b-button>
          </div>
        </div>
      </div>
    </card>
    <div
      slot="body"
      class="material-card-content executionCardContent"
    >
      <execution-table
        :key="tableKey"
        :checked-rows="checkedExecutions"
        :show-canceled="showCanceled || (filtersState && !!filtersState.DEVICE.value.selectedValue)"
        :execution-group="executionGroup"
        :displaying-execution-details="displayingExecutionDetails"
        :filters-state="filtersState"
        @enableSwitch="forceShowCanceled = $event"
        @update="checkedExecutions = $event"
      />
    </div>
    <footer
      slot="footer"
      class=""
    >
      <div class="content has-text-centered card-footer">
        <p class="executions-footer">
          <span>
            Started
          </span>
          <span>
            <timeago
              :key="executionGroup.id"
              :since="executionGroup.createdAt"
              no-future
              no-date
            /> (<strong>{{ executionGroup.createdAt | moment }}</strong>)
          </span>
          <span v-if="executionUser">
            by
            <b v-if="hasParameters">
              {{ executionUser.email }}
            </b>
            <b v-else>
              {{ executionUser.email }}.
            </b>
          </span>
          <execution-Parameters
            v-if="hasParameters"
            :execution-parameters="executionGroup.parameters"
          />
        </p>
      </div>
    </footer>
  </card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import toastMessage from '@/helpers/toastMessage';
import ExecutionStatus from '@/helpers/executionStatus';
import executionResultParameters from './ExecutionResultParameters.vue';
import executionTable from './ExecutionTable.vue';
import vtulEnums from '../../../../cross/index';

const { operationalApiErrors } = vtulEnums.enum.api;
const { executionHelper } = vtulEnums.enum.execution;

export default {
  name: 'ExecutionsGroupCard',
  components: {
    'execution-Parameters': executionResultParameters,
    card,
    'execution-table': executionTable,
  },
  props: {
    executionGroup: {
      type: Object,
      required: true,
    },
    displayingExecutionDetails: {
      type: Boolean,
      required: true,
    },
    filtersState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showCanceled: false,
      executionStatus: vtulEnums.enum.execution.executionStatus,
      executionActionsEnum: vtulEnums.enum.execution.executeAction.enums
        .filter((x) => !x.value.hiddenActionResult),
      checkedExecutions: [],
      tableKey: 0,
      executionStatusHelper: ExecutionStatus,
      canceledExecutionsTotal: 0,
      forceShowCanceled: false,
      haveRelatedExecutions: true,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('user', { getUserInStore: 'get' }),
    ...mapGetters('executions', { findExecutionInStore: 'find' }),
    checkedCancelableExecutions() {
      return this.checkedExecutions.filter((x) => (
        this.executionStatusHelper.getStatus(x) === this.executionStatus.SCHEDULED.value));
    },
    executionUser() {
      return this.getUserInStore(this.executionGroup.userId);
    },
    countExecutionsCanceled() {
      const executionsCanceled = this.findExecutionInStore({
        query: {
          companyId: this.currentCompany.id,
          executionGroupId: this.executionGroup.id,
          exitCode: {
            $or: [operationalApiErrors.OFFLINE_DEVICE.value.code,
              operationalApiErrors.CANCELED_EXECUTION.value.code],
          },
          $limit: 0,
        },
      });
      return executionsCanceled.total;
    },
    hasParameters() {
      return this.executionGroup.parameters
      && Object.keys(this.executionGroup.parameters).length > 0;
    },
  },
  watch: {
    countExecutionsCanceled() {
      this.countCanceledExecutionsFromApi();
    },
    forceShowCanceled() {
      this.showCanceled = true;
    },
  },
  created() {
    this.fetchUserFromApi();
    this.getCurrentExecutions();
    this.countCanceledExecutionsFromApi();
  },
  methods: {
    ...mapActions('user', { getUser: 'get' }),
    ...mapActions('executions', { findExecution: 'find' }),
    ...mapActions('executions-helper', { cancelExecutions: 'find' }),
    goToExecution(item) {
      this.$router.push(`/${this.$route.params.companyId}/execution/${item.id}/1`);
    },
    fetchUserFromApi() {
      this.getUser(this.executionGroup.userId);
    },
    async getCurrentExecutions() {
      await this.findExecution({
        query: {
          companyId: this.currentCompany.id,
          executionGroupId: this.executionGroup.id,
          startedOn: null,
          finishedOn: null,
          $limit: 1,
        },
      });
      this.checkIfHaveExecutions();
    },
    async countCanceledExecutionsFromApi() {
      const { total } = await this.findExecution({
        query: {
          companyId: this.currentCompany.id,
          executionGroupId: this.executionGroup.id,
          exitCode: {
            $or: [operationalApiErrors.OFFLINE_DEVICE.value.code,
              operationalApiErrors.CANCELED_EXECUTION.value.code],
          },
          $limit: 0,
        },
      });
      this.canceledExecutionsTotal = total;
    },
    async sendExecutionsToCancel() {
      try {
        await this.cancelExecutions({
          query: {
            type: executionHelper.CANCEL_EXECUTIONS.value,
            executionIds: this.checkedCancelableExecutions.map((x) => x.id),
            companyId: this.currentCompany.id,
          },
        });
        this.checkedExecutions = [];
        this.tableKey += 1; // Force to redraw the table
      } catch (error) {
        toastMessage.showError('We could not cancel the executions, please try again.');
      }
    },
    checkIfHaveExecutions() {
      this.haveRelatedExecutions = !!(this.executionGroup.executionsWaitingToStart !== 0
      || this.executionGroup.executionsRunning !== 0
      || this.executionGroup.executionsFinished !== 0);
      if (!this.haveRelatedExecutions && this.$route.params.executionGroupId) {
        this.$router.replace(`/${this.$route.params.companyId}/executions`);
      }
    },
  },
};
</script>
