<template>
  <b-table
    ref="executionTable"
    :data="executionsResult"
    :loading="executionsResult.length === 0 && (showCanceled || isFindExecutionsPending)"
    backend-pagination
    backend-sorting
    :paginated="totalExecutions > maxExecutionsPerPage"
    :pagination-simple="true"
    :total="totalExecutions"
    :per-page="maxExecutionsPerPage"
    :mobile-cards="false"
    default-sort-direction="desc"
    default-sort="exitCode"
    detailed
    detail-key="id"
    :show-detail-icon="true"
    class="execution-table"
    :current-page="currentPage"
    checkable
    checkbox-position="right"
    :checked-rows.sync="localCheckedRows"
    :is-row-checkable="(row) =>
      executionStatusHelper.getStatus(row) === executionStatus.SCHEDULED.value"
    @page-change="changePage"
    @sort="updateSort"
  >
    <b-table-column
      width="250"
      field="deviceName"
      label="Device"
    >
      <template v-slot="props">
        <router-link
          :to="`/${$route.params.companyId}/device/${props.row.deviceId}`"
          class="is-text"
          style="padding:0px;color:#4a4a4a;text-decoration: underline;white-space: nowrap"
        >
          {{ props.row.deviceName }}
        </router-link>
      </template>
    </b-table-column>
    <b-table-column
      field="exitCode"
      label="Result"
      centered
      sortable
      width="100"
      style="white-space: nowrap"
    >
      <template v-slot="props">
        <span
          v-if="!props.row.exitCode"
          class="tag executionStatusTags is-primary is-light"
        >┄
        </span>
        <span
          v-else-if="props.row.exitCode == 0"
          class="tag executionStatusTags is-success"
        >
          Success
        </span>

        <span
          v-else-if="props.row.exitCode == operationalApiErrors.OFFLINE_DEVICE.value.code
            || props.row.exitCode == operationalApiErrors.CANCELED_EXECUTION.value.code"
          class="tag executionStatusTags is-dark"
        >
          {{ props.row.exitCode }}
        </span>

        <span
          v-else
          class="tag executionStatusTags is-warning"
        >
          {{ props.row.exitCode }}
        </span>
      </template>
    </b-table-column>
    <b-table-column
      field="executionResult"
      sortable
      label="Result details"
    >
      <template v-slot="props">
        <execution-details
          :key="props.row.id"
          :show-text="false"
          :execution-result="props.row.executionResult"
          :execution-row="props.row"
          :view-result-execution="viewResultExecution"
          :view-finished-at-execution="props.row.finishedOn === null ? false : true"
        />
      </template>
    </b-table-column>
    <b-table-column
      field="status"
      label="Status"
      width="100"
      centered
      style="white-space: nowrap"
    >
      <template v-slot="props">
        <span
          v-show="executionStatusHelper.getStatus(props.row)
            === executionStatus.SCHEDULED.value"
          class="tag executionStatusTags is-primary is-light"
        >
          Scheduled
        </span>
        <span
          v-show="executionStatusHelper.getStatus(props.row) === executionStatus.RUNNING.value"
          class="tag executionStatusTags is-primary"
        >
          <b-icon
            custom-class="icon-spin"
            icon="rotate-right"
          />&nbsp;&nbsp;Running
        </span>
        <span
          v-show="executionStatusHelper.getStatus(props.row) === executionStatus.FINISHED.value"
          class="tag executionStatusTags is-primary"
        >
          Finished
        </span>
      </template>
    </b-table-column>

    <template slot="empty">
      <div class="content has-text-grey has-text-centered">
        There are no executions to show
      </div>
    </template>

    <template
      slot="detail"
      slot-scope="props"
    >
      <div
        class="is-flex details-toolbar"
        style="border-bottom: 1px solid lightgrey; margin-bottom: 10px"
      >
        <div
          style="width: 100%;"
          class="toolbar-columns"
        >
          <div style="margin-right:30px;">
            <p>
              <span style="margin-right:30px">
                <b>Started:</b>
                <span v-if="props.row.startedOn">
                  {{ props.row.startedOn | moment }}
                </span>
                <span v-else> Not yet</span>
              </span>
              <span>
                <b>Finished:</b>
                <span v-if="props.row.finishedOn">
                  {{ props.row.finishedOn | moment }}
                </span>
                <span v-else> Not yet</span>
              </span>
            </p>
          </div>
          <div>
            <button
              v-if="props.row.executionResult"
              v-clipboard:copy="props.row.executionResult"
              v-clipboard:success="onCopy"
              v-clipboard:error="onCopyError"
              class="button is-text"
            >
              <span class="icon">
                <b-icon
                  icon="clipboard-outline"
                  size="is-small"
                />
              </span>
              <b>Copy Result</b>
            </button>
          </div>
        </div>
      </div>
      <article class="media">
        <execution-details
          :key="props.row.id"
          show-text
          :execution-row="props.row"
        />
      </article>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import ExecutionStatus from '@/helpers/executionStatus';
import toastMessage from '@/helpers/toastMessage';
import executionResultDetails from './ExecutionResultDetails.vue';
import vtulEnums from '../../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { operationalApiErrors } = vtulEnums.enum.api;

export default {
  name: 'ExecutionTable',
  components: {
    'execution-details': executionResultDetails,
  },
  mixins: [makeFindMixin({ service: 'executions', watch: true })],
  props: {
    showCanceled: {
      type: Boolean,
      default: false,
    },
    executionGroup: {
      type: Object,
      required: true,
    },
    displayingExecutionDetails: {
      type: Boolean,
      required: true,
    },
    filtersState: {
      type: Object,
      default: () => {},
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: this.displayingExecutionDetails
        && this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
      sortField: 'exitCode',
      sortDirection: 'desc',
      executionStatus: vtulEnums.enum.execution.executionStatus,
      rowsDetailsOpen: [],
      operationalApiErrors,
      executionStatusHelper: ExecutionStatus,
      maxExecutionsPerPage: this.displayingExecutionDetails ? 50 : 5,
      skipExecutions: 0,
      localCheckedRows: this.checkedRows,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    queryForSearchExecutions() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by totalExecutions() method.
      const query = {
        $sort: {
          [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1],
          id: 1,
        },
        companyId: this.currentCompany.id,
      };
      if (this.filtersState && this.filtersState.DEVICE.value.selectedValue) {
        query.deviceId = this.filtersState.DEVICE.value.selectedValue;
      }
      query.executionGroupId = this.executionGroup.id;

      if (!this.showCanceled) {
        query.exitCode = {
          $or: [null, {
            $not: [operationalApiErrors.OFFLINE_DEVICE.value.code,
              operationalApiErrors.CANCELED_EXECUTION.value.code],
          }],

        };
      }

      if (this.filtersState) {
        const state = this.filtersState.STATE.value.selectedValue;
        if (state === this.executionStatus.SCHEDULED.value) {
          query.finishedOn = null;
          query.startedOn = null;
        } else if (state === this.executionStatus.RUNNING.value) {
          query.finishedOn = null;
          query.startedOn = { $not: null };
        } else if (state === this.executionStatus.FINISHED.value) {
          query.finishedOn = { $not: null };
          query.startedOn = { $not: null };
        }
      }
      return query;
    },
    currentPaginationQuery() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by executionResult() method
      return {
        $limit: this.maxExecutionsPerPage,
        $skip: this.skipExecutions,
      };
    },
    executionsParams() {
      const query = JSON.parse(JSON.stringify(this.queryForSearchExecutions));
      query.$sort = {
        [this.sortField]: this.sortDirection === 'asc' ? 1 : -1,
        id: 1,
      };
      return {
        query: {
          ...this.currentPaginationQuery,
          ...query,
        },
      };
    },
    executionsFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchExecutions,
        },
      };
    },
    executionsResult() {
      const queryId = JSON.stringify(this.queryForSearchExecutions);
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.executionsPaginationData.default
          || !this.executionsPaginationData.default[queryId]
          || !this.executionsPaginationData.default[queryId][paginationId]) {
        return [];
      }
      const executionIds = this.executionsPaginationData.default[queryId][paginationId].ids;

      const executions = this.findExecutionsInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: executionIds,
          },
          $sort: {
            [this.sortField]: this.sortDirection === 'asc' ? 1 : -1,
          },
        },
      });
      return executions.data;
    },
    totalExecutions() {
      const queryId = JSON.stringify(this.queryForSearchExecutions);
      if (!this.executionsPaginationData.default
          || !this.executionsPaginationData.default[queryId]) {
        return 0;
      }
      const { total } = this.executionsPaginationData.default[queryId];
      if (total === 0) {
        this.$emit('enableSwitch', true);
      }
      return total;
    },
  },
  watch: {
    showCanceled(hide) {
      if (hide) {
        this.hideExecutionsResults();
      }
    },
    localCheckedRows(executions) {
      this.$emit('update', executions);
    },
  },
  created() {
    this.changePage(this.currentPage);
  },
  methods: {
    sortByDate(a, b, isAsc) {
      let dateA = new Date(a.finishedOn);
      let dateB = new Date(b.finishedOn);

      if (a.finishedOn === null) {
        dateA = new Date();
      }
      if (b.finishedOn === null) {
        dateB = new Date();
      }

      if (isAsc) {
        return dateA.getTime() - dateB.getTime();
      }
      return dateB.getTime() - dateA.getTime();
    },
    updateSort(currentSort, currentDirection) {
      this.sortDirection = currentDirection;
      this.sortField = currentSort;
    },
    viewResultExecution(row, hide) {
      this.$refs.executionTable.toggleDetails(row);
      if (hide) {
        const position = this.rowsDetailsOpen.findIndex((x) => x.id === row.id);
        if (position !== -1) {
          this.rowsDetailsOpen.splice(position, 1);
        }
        const openButton = document.getElementById(`${row.id}-openButton`);
        if (openButton !== null && openButton !== undefined) {
          openButton.style.visibility = 'visible';
        }
      } else {
        if (row.exitCode === operationalApiErrors.OFFLINE_DEVICE.value.code) {
          this.rowsDetailsOpen.push(row);
        }
        const openButton = document.getElementById(`${row.id}-openButton`);
        if (openButton !== null && openButton !== undefined) {
          openButton.style.visibility = 'hidden';
        }
      }
    },
    hideExecutionsResults() {
      const currentRowsOpen = JSON.parse(JSON.stringify(this.rowsDetailsOpen));
      currentRowsOpen.forEach((row) => {
        this.viewResultExecution(row, true);
      });
    },
    onCopy() {
      toastMessage.showSuccess('Result copied to clipboard');
    },
    onCopyError() {
      toastMessage.showError(operationalUiErrors.UNCOPYRIGHTABLE_CLIPBOARD_EXECUTION.value.message);
    },
    changePage(page) {
      if (this.displayingExecutionDetails) {
        this.$router.replace({ params: { page } });
      }
      if (page) {
        this.currentPage = page;
      }
      this.skipExecutions = (this.currentPage - 1) * this.maxExecutionsPerPage;
    },
  },
};
</script>

<style>
.executions-footer {
  word-wrap: break-word;
  padding: 20px;
  width: 100%;
}
.execution-table > .detail-container {
  max-height: 300px;
  overflow-x: overlay;
  padding-top: 0px !important;
}
.details-toolbar {
  flex-flow:row;
  position: sticky; top: 0;
  background: #fafafa;
  height: 50px;
  align-items: center;
}
.toolbar-columns {
  display: flex;
  flex-flow:row;
  align-items: center;
}
.execution-table > .detail td {
  padding-top: 0px !important;
}
.execution-table > .table-wrapper {
  overflow-x: auto;
}
.executionStatusTags {
  min-width: 80px;
}
@media screen and (max-width: 840px) {
  .toolbar-columns {
    display: initial;
  }
  .details-toolbar {
    height: 66px;
  }
}
</style>
